@font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-BLACK.TTF') format('truetype');
    font-weight: 900;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-BLACKITALIC.TTF') format('truetype');
    font-weight: 900;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-BOLD.TTF') format('truetype');
    font-weight: 700;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-BOLDITALIC.TTF') format('truetype');
    font-weight: 700;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-EXTRABOLD.TTF') format('truetype');
    font-weight: 800;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-EXTRABOLDITALIC.TTF') format('truetype');
    font-weight: 800;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-EXTRALIGHT.TTF') format('truetype');
    font-weight: 200;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-EXTRALIGHTITALIC.TTF') format('truetype');
    font-weight: 200;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-ITALIC.TTF') format('truetype');
    font-weight: 400;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-LIGHT.TTF') format('truetype');
    font-weight: 300;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-LIGHTITALIC.TTF') format('truetype');
    font-weight: 300;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-MEDIUM.TTF') format('truetype');
    font-weight: 500;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-MEDIUMITALIC.TTF') format('truetype');
    font-weight: 500;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-REGULAR.TTF') format('truetype');
    font-weight: 400;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-SEMIBOLD.TTF') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-SEMIBOLDITALIC.TTF') format('truetype');
    font-weight: 600;
    font-style: italic;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-THIN.TTF') format('truetype');
    font-weight: 100;
    font-style: normal;
  }
  
  @font-face {
    font-family: 'Raleway';
    src: url('../src/fonts/RALEWAY-THINITALIC.TTF') format('truetype');
    font-weight: 100;
    font-style: italic;
  }
  