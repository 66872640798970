/* JaraFestPoster.css */
@import '../index.css';

.poster-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  padding: 0 10px;
  box-sizing: border-box;
  overflow: hidden;
  background-color: #fdf3a2; /* Añadir esta línea */
}

.poster {
  position: relative;
  width: 100%;
  height: 100%;
}

.central-image,
.central-image-translucent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/images/background_pepapig.png');
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.central-image-translucent {
  opacity: 0.3;
}

.date {
  position: absolute;
  top: 7%;
  left: 2%;
  font-size: 2.5vw;
  writing-mode: vertical-rl;
  text-orientation: mixed;
  line-height: 1.5;
}

.title {
  position: absolute;
  top: 7%;
  right: 2%;
  width: 20%;
  height: auto;
  animation: slideIn 1s ease-out;
  transition: all 0.3s ease;
}

.animated-text-block {
  position: absolute;
  bottom: 10%;
  left: 2%;
  animation: slideInLeft 1s ease-out forwards;
  font-size: 4vw;
  font-weight: 700;
}

.location {
  font-size: 2.5vw;
  font-weight: 400;
  margin-bottom: 2vh;
}

.lineup {
  font-size: 2vw;
  text-align: left;
  margin-bottom: 2vh;
  margin-top: 2vh;
  font-weight: 700;
}

.hashtag {
  font-size: 1.5vw;
  font-weight: 700;
  text-align: left;
  line-height: 1.2;
  white-space: pre-line;
  margin-top: 1vh;
}

.all-links {
  position: absolute;
  bottom: 2%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 2%;
  box-sizing: border-box;
}

.all-links a {
  color: var(--text-color);
  text-decoration: none;
  transition: color 0.3s ease;
  white-space: nowrap;
  font-size: 1.5vw;
  margin: 0 1vw;
  font-weight: 700;
}

.all-links a:hover,
.all-links a:active {
  color: var(--accent-color);
}

@media (max-width: 768px) {
  .date {
    font-size: 4vw;
    top: 6%;
    left: 1%;
  }
  
  .title {
    width: 30%;
    top: 20%; /* Adjusted: moved down from 5% to 20% */
    right: 5%;
  }
  
  .animated-text-block {
    bottom: 12%;
    font-size: 5vw;
  }

  .location {
    font-size: 4vw;
  }
  
  .lineup {
    font-size: 4vw;
  }
  
  .hashtag {
    font-size: 3vw;
  }

  .all-links {
    display: none;
  }

  .central-image {
    top: 6%;
    height: 60%;
    background-size: contain;
    background-position: left;
  }
}

@keyframes slideIn {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}

@keyframes slideInLeft {
  from { transform: translateX(-100%); }
  to { transform: translateX(0); }
}
