/* ContentPages.css */
@import '../index.css';

.content-page-container {
  position: relative;
  min-height: 100vh;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: auto;
}

.body-color-layer {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fdf3a2;
  z-index: -2;
}

.content-page-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../public/images/background_pepapig.png');
  background-size: cover;
  background-position: center;
  opacity: 0.1;
  z-index: -1;
}

.content-page {
  position: relative;
  z-index: 1;
  max-width: 800px;
  margin: 0 auto;
  padding: 60px 0 40px;
  background-color: transparent;
}

.fixed-elements {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  transition: opacity 0.2s ease;
}

.content-title {
  position: fixed;
  top: 50px;
  right: 50px;
  font-size: 2.5rem;
  font-weight: 900;
  margin-bottom: 20px;
  text-align: right;
  white-space: nowrap;
  background-color: #fdf2a200;
  padding: 10px;
  transition: opacity 0.2s ease;
}

.content-title.hidden {
  opacity: 0;
  pointer-events: none;
}

.content-body {
  font-size: 1rem;
  line-height: 1.6;
  margin-right: 60px;
  padding-top: 60px;
}

.content-body h2 {
  font-size: 2rem;
  margin: 30px 0 15px;
}

.content-body p,
.content-body ul {
  margin-bottom: 15px;
}

.content-body ul {
  padding-left: 20px;
}

.poster-container .title {
  position: fixed;
  top: 7%;
  right: 2%;
  width: 20%;
  height: auto;
  animation: slideIn 1s ease-out;
  transition: all 0.3s ease;
}

.hamburger-menu,
.home-button {
  transition: opacity 0.2s ease;
}

/* Estilos actualizados para el contenido de "Cómo llegar" */
.como-llegar-content {
  text-align: left;
}

.como-llegar-content h3 {
  text-align: left;
}

.como-llegar-content p {
  text-align: left;
}

/* Estilos para los enlaces "Ver en el mapa" */
.map-link-container {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 10px 0;
}

.map-link {
  display: inline-block;
  text-align: center;
  color: rgb(202, 138, 4);
  text-decoration: none;
  font-weight: bold;
  transition: color 0.2s;
}

@media (max-width: 768px) {
  .content-title {
    font-size: 1.8rem;
    right: 10px;
  }

  .content-body {
    font-size: 1rem;
    margin-right: 40px;
  }

  .content-body h2 {
    font-size: 1.5rem;
  }

  .poster-container .title {
    width: 30%;
    top: 8%;
    right: 5%;
  }
}

@keyframes slideIn {
  from { transform: translateX(100%); }
  to { transform: translateX(0); }
}