/* lineup-styles.css */

.lineup-container {
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  
  .lineup-line {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
  }
  
  .lineup-bullet {
    font-size: 1.2em;
    color: #000000;
    margin: 0 4px;
  }
  
  .lineup-artist {
    font-weight: bold;
    color: #CA8A04;
    display: inline-block;
  }
  
  .lineup-artist-link {
    text-decoration: none;
  }
  
  .lineup-artist-special {
    color: black;
  }
  
  /* Desktop styles */
  @media (min-width: 1024px) {
    .lineup-container {
      font-size: 2.1rem;
    }
    
    .lineup-bullet {
      font-size: 1.5em;
    }
    
    .programa-content {
      margin-top: 2rem;
    }
  }
  
  /* Tablet styles */
  @media (min-width: 768px) and (max-width: 1023px) {
    .lineup-container {
      font-size: 1.5rem;
    }
    
    .lineup-bullet {
      font-size: 1.3em;
    }
    
    .programa-content {
      margin-top: 1.5rem;
    }
  }
  
  /* Phone styles */
  @media (max-width: 767px) {
    .lineup-container {
      font-size: 0.9rem;
    }
    
    .lineup-bullet {
      font-size: 1em;
    }
  }