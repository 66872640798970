/* src/index.css */
@import url('./fonts.css');

* {
  font-family: 'Raleway', sans-serif;
}
/* shared-styles.css */
:root {
  --main-bg-color:#fdf3a200;
  --accent-color: rgb(240, 178, 62);
  --text-color: black;
  --font-family: 'Raleway', sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: var(--font-family);
  color: var(--text-color);
}

/* Back/Home button styles */
.back-button,
.home-button {
  position: fixed;
  top: 20px;
  left: 20px;
  width: 40px;
  height: 40px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--accent-color);
  border-radius: 50%;
  padding: 5px;
  transition: background-color 0.3s ease;
  border: none;
  cursor: pointer;
}

.back-button svg,
.home-button svg {
  width: 24px;
  height: 24px;
  color: var(--text-color);
}

.back-button:hover,
.home-button:hover {
  background-color: var(--main-bg-color);
}

@media (max-width: 768px) {
  .back-button,
  .home-button {
    top: 10px;
    left: 10px;
    width: 30px;
    height: 30px;
  }

  .back-button svg,
  .home-button svg {
    width: 18px;
    height: 18px;
  }
}