/* src/components/HamburgerMenu.css */
.hamburger-menu {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 3;
}

.menu-icon {
  width: 30px;
  height: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.menu-icon div {
  width: 100%;
  height: 3px;
  background-color: black;
  transition: 0.3s;
}

.menu-icon.open .bar1 {
  transform: rotate(-45deg) translate(-5px, 6px);
}

.menu-icon.open .bar2 {
  opacity: 0;
}

.menu-icon.open .bar3 {
  transform: rotate(45deg) translate(-5px, -6px);
}

.menu-content {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: rgba(255, 255, 255, 0); /* Fondo completamente transparente */
  display: flex;
  flex-direction: column;
  padding: 10px;
  display: none; /* Inicialmente oculto */
}

.menu-content.show {
  display: flex;
}

.menu-content a {
  color: black;
  text-decoration: none;
  padding: 10px 20px;
  text-align: right;
  transition: color 0.3s;
}

.menu-content a:hover,
.menu-content a:active {
  color: rgb(240, 178, 62); /* Cambia el color de las letras al seleccionar */
}

.hidden {
  display: none !important;
}
